// config.js

const bizIcon = 'https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg';
const myBizIcon = 'https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg';
const imageOne = "https://static.wixstatic.com/media/01c3a8_4c310b6a6733404e975c2a33e6924d4f~mv2.jpg/v1/fit/w_1094,h_810,q_90/01c3a8_4c310b6a6733404e975c2a33e6924d4f~mv2.webp";
const imageTwo = "https://static.wixstatic.com/media/01c3a8_aad7be2badcc47c4959f4a385eb1d93b~mv2.jpg/v1/fit/w_1094,h_810,q_90/01c3a8_aad7be2badcc47c4959f4a385eb1d93b~mv2.webp";
const imageThree = "https://static.wixstatic.com/media/84770f_154a78c7f17f47259e0e6aaf22ac2891~mv2_d_5760_3840_s_4_2.jpg/v1/fit/w_711,h_810,q_90/84770f_154a78c7f17f47259e0e6aaf22ac2891~mv2_d_5760_3840_s_4_2.webp";
const imageFour = "https://static.wixstatic.com/media/01c3a8_1646c00baea44d339c6bc33c01da0748~mv2.jpg/v1/fit/w_1094,h_810,q_90/01c3a8_1646c00baea44d339c6bc33c01da0748~mv2.webp";
const imageFive = "https://static.wixstatic.com/media/01c3a8_79737afd8e79410d960ac25c26764669~mv2.jpg/v1/fit/w_1094,h_810,q_90/01c3a8_79737afd8e79410d960ac25c26764669~mv2.webp";

const redirectLink = "https://www.fgbackflowtestingandrepair.com/";

export const siteConfig = {
  title: "F&G Back Flow Testing & Repair",
  metaDescription: "F&G Back Flow Testing & Repair",
  theme: {
    primaryColor: "red-600",
    secondaryColor: "gray-900",
    accentColor: "text-red-600"
  },
  logo: {
    src: bizIcon,
    alt: "F&G Back Flow Testing & Repair Logo",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "F&G Back Flow Testing & Repair",
    headline: "Your Trusted Backflow Testing and Repair Experts in Dallas-Fort Worth",
    address: "Dallas-Fort Worth Metroplex, TX, USA",
    description: `
        F&G Back Flow Testing & Repair specializes in professional backflow prevention, testing, and repair services, ensuring the safety and compliance of your water systems. Proudly serving the Dallas-Fort Worth Metroplex, TX, our team is dedicated to providing top-quality solutions tailored to residential and commercial needs. With years of expertise and a commitment to excellence, we deliver reliable, efficient, and trustworthy services to protect your water systems and ensure peace of mind.
    `,
  },
  images: {
    business: {
      src: imageOne,
      alt: "Your Trusted Backflow Testing and Repair Experts in Dallas-Fort Worth",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
        description: 
          "Compliant & Certified: Ensuring all backflow testing and repair services meet local and state regulations for safety and reliability.",
    },
    {
        description: 
          "Dependable & Professional: Providing timely and trustworthy solutions to protect your water systems and maintain compliance.",
    },
    {
        description: 
          "Experienced & Dedicated: With years of expertise, our team is committed to delivering exceptional service and customer satisfaction.",
    },
  ],
  services: {
    sectionTitle: "Our Backflow Testing & Repair Services",
    description: 
      "F&G Back Flow Testing & Repair specializes in professional backflow testing and repair services designed to ensure the safety and compliance of your water systems. Serving the Dallas-Fort Worth Metroplex, we deliver dependable and efficient solutions to protect your property.",
    callouts: [
      {
        name: 'Backflow Testing',
        description: 
          "Our certified technicians conduct thorough backflow testing to ensure your water systems are compliant with local and state safety standards, providing peace of mind and reliable performance.",
        imageSrc: imageTwo,
        imageAlt: 'Certified technician performing backflow testing on a residential property.',
      },
      {
        name: 'Backflow Repair',
        description: 
          "We provide expert backflow repair services to address any issues and maintain the integrity of your water systems, ensuring uninterrupted and safe operation.",
        imageSrc: imageThree,
        imageAlt: 'Technician repairing a backflow prevention device for a commercial property.',
      },
      {
        name: 'Lawn Care',
        description: 
          "Enhance the beauty and health of your outdoor spaces with our professional lawn care services, tailored to maintain lush, green, and well-manicured lawns year-round.",
        imageSrc: imageFour,
        imageAlt: 'Team providing professional lawn care service for a residential property.',
      },
    ]
  },
  about: {
    sectionTitle: "About Us",
    description:   
      "F&G Back Flow Testing & Repair is your trusted partner for maintaining lush lawns and ensuring safe water systems in the Dallas-Fort Worth Metroplex. With years of experience, our dedicated team specializes in professional backflow testing and repair solutions alongside top-tier lawn care services. We are committed to exceeding customer expectations through reliable, compliant, and efficient services that enhance the health of your property and protect your water supply.",
    image: {
      src: imageFive,
      alt: "F&G Back Flow Testing & Repair team delivering professional services in the Dallas-Fort Worth Metroplex.",
      width: 1200,
      height: 800,
    },
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "Dallas - Fort Worth Metroplex, DFW, TX",
    googleMapsEmbedUrl: 
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3347.703589569689!2d-97.33076568481956!3d32.75548847946325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e6e9caa6d0bff%3A0xabcdef123456789!2sDallas%20-%20Fort%20Worth%20Metroplex%2C%20DFW%2C%20TX!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=Dallas+-+Fort+Worth+Metroplex,+DFW,+TX',
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=Dallas+-+Fort+Worth+Metroplex,+DFW,+TX&FORM=HDRSC6',
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/Dallas+-+Fort+Worth+Metroplex,+DFW,+TX/@32.755488,-97.330765,15a,814.57589625d,35y,0h,0t,0r',
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/Dallas+-+Fort+Worth+Metroplex,+DFW,+TX/@32.755488,-97.330765,15z',
      },
    ],  
  },
  contact: {
    sectionTitle: "Contact F&G Back Flow Testing & Repair",
    description: 
      "Ensure your lawn and water systems are in top condition with F&G Back Flow Testing & Repair. From lawn care to professional backflow testing and repair, our team is ready to assist. Contact us today for expert advice, reliable services, and a free estimate tailored to your needs.",
    socialLinks: {
      facebook: redirectLink,     
      instagram: redirectLink,     
      twitter: redirectLink,     
      linkedin: redirectLink,     
      youtube: redirectLink,     
    },
    contactNumbers: [
      "Mobile: (469) 939-5642",
    ],
    logo: bizIcon,
    emailRecipient: "franciscoalanis@gmail.com"
  },
  footer: {
    bizSolutionsLogo: myBizIcon
  }
};


